body {
  margin: 0;
  font-family: "roboto slab", Arial;
}
header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px rgba(0,0,0,0.1) solid;
  align-items: center;
}
header .website-title {
  font-size: 1em;
  flex: 0.3;
  padding-left: 10px;
  font-weight: bold;
  font-size: 1.3em;
}
header .header-search {
  flex: 0.3;
}
header ul {
  flex: 1;
  display: flex;
  list-style-type: none;
  justify-content: center;
}
header ul li {
  padding: 0 10px;
}
header ul a {
  text-decoration: none;
}
header ul a:hover {
  text-decoration: underline;
}
footer {
  border-top: 1px rgba(0,0,0,0.1) solid;
}
